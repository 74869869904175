var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticClass: "mb white-bg",
      attrs: {
        align: "middle",
        id: "docs",
        justify: "space-around",
        type: "flex"
      }
    },
    _vm._l(_vm.docs, function(doc) {
      return _c(
        "a-col",
        {
          key: doc.id,
          staticStyle: { "margin-bottom": "20px" },
          attrs: { md: 7, xs: 22 }
        },
        [
          _c(
            "a-card",
            { attrs: { hoverable: "" } },
            [
              _c("a-card-meta", [
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("\n          " + _vm._s(doc.titre) + "\n        ")
                ]),
                _c(
                  "span",
                  { attrs: { slot: "description" }, slot: "description" },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.date(doc.created_at)))]),
                    _c("br"),
                    _c(
                      "a-button",
                      {
                        staticStyle: {
                          "margin-top": "4%",
                          "text-transform": "uppercase"
                        },
                        attrs: { type: "primary", block: "" },
                        on: {
                          click: function($event) {
                            return _vm.downloadDoc(doc.file_url)
                          }
                        }
                      },
                      [_vm._v("télécharger")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }