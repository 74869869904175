<template>
  <a-row
    align="middle"
    id="docs"
    class="mb white-bg"
    justify="space-around"
    type="flex"
  >
    <a-col
      v-for="doc in docs"
      :key="doc.id"
      :md="7"
      :xs="22"
      style="margin-bottom: 20px"
    >
      <a-card hoverable style="">
        <a-card-meta>
          <span slot="title">
            {{ doc.titre }}
          </span>
          <span slot="description">
            <span>{{ date(doc.created_at) }}</span>
            <br />
            <a-button
              type="primary"
              @click="downloadDoc(doc.file_url)"
              block
              style="margin-top: 4%; text-transform: uppercase"
              >télécharger</a-button
            >
          </span>
        </a-card-meta>
      </a-card>
    </a-col>
  </a-row>
</template>
<script>
export default {
  name: "DocsToDownload",
  props: { docs: { type: Array } },
  methods: {
    downloadDoc(fileUrl) {
      this.previewDocument(fileUrl);
    },
  },
};
</script>